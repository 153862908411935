import { NAV_HEIGHT } from '@/data/constants'

export const useScrollToElement = () => {
  const scrollToElement = (selector: string) => {
    const item = document.getElementById(selector)
    if (!item) {
      return
    }

    const { top } = item.getBoundingClientRect()
    window.scrollBy({
      top: top - NAV_HEIGHT,
      behavior: 'smooth',
    })
  }

  return {
    scrollToElement,
  }
}
